@import url("satoshi.css");
html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.dropzone {
  display: flex;
  flex-direction: column;
  background-color: #dce5db;
  text-align: center;
  border: 1px dashed #000000;
  border-radius: 20px;
  height: 400px;
}
